import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/pharmaceuticals");
  }
  get(id) {
    return axios.get(`/pharmaceuticals/${id}`);
  }
  create(data) {
    return axios.post("/pharmaceuticals", data);
  }
  update(id, data) {
    return axios.put(`/pharmaceuticals/${id}`, data);
  }
  getCompanies() {
    return axios.get("/lkbCompany")
  }
}
export default new DataService();